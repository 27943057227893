import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { walletData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";
import { MobileApp } from "../sections/MobileApp";
import { contactLink } from "../constants/links";
import { primaryColor } from "../components/styled/Theme"; // Import the primary color
import home_hero from "../assets/home_hero.png";
import Screen1 from "../assets/Screen1.png";
import Screen2 from "../assets/Screen2.png";
import Capture2 from "../assets/Capture2.png";

export const HeaderSection1 = () => (
  <div className="">
    <Wrapper className="container flexSpaceCenter">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              The <HighlightSpan>Better</HighlightSpan> Way To Invest <HighlightSpan>Sustainably</HighlightSpan>
            </h1>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
          <div className="font18 semiBold">
            Everpesa Invest is the ultimate <b>P2P</b> investment platform designed to empower your 
            financial journey. Connect with small businesses and SACCOs, support local communities, 
            and enjoy returns on your investments while contributing to a greener future. Join your 
            friends in using Everpesa and be part of a community committed to <b>sustainable investing.</b>
          </div>
        </div>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          {/* <HeaderButton to={contactLink}>Start Today</HeaderButton> */}
          <HeaderButton link="https://invest.everpesa.com/">
            Invest Today
          </HeaderButton>
        </div>
      </LeftSide>
      <RightSide>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={home_hero} alt="Investment Platform" />
        </div>
      </RightSide>
    </Wrapper>
  </div>
);

export const HeaderSection2 = () => (
  <StyledHeaderSection2>
    <Wrapper className="container flexSpaceCenter">
      <RightSide>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={Screen1} alt="Investment Platform" />
        </div>
      </RightSide>
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Invest in Opportunities
            </h1>
          </div>
          
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
          <div className="font18 semiBold">
            Choose from a variety of investment options, including short-term loans and 
            green bonds, tailored to your financial goals and risk appetite.
          </div>
        </div>

      </LeftSide>
    </Wrapper>
  </StyledHeaderSection2>
);


export const HeaderSection3 = () => (
  <div className="">
    <Wrapper className="container flexSpaceCenter">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Support Small <HighlightSpan>Businesses</HighlightSpan>
            </h1>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
          <div className="font18 semiBold">
            Your investments provide crucial funding to small businesses and entrepreneurs, 
            helping them grow and succeed.
          </div>
        </div>

      </LeftSide>
      <RightSide>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={Capture2} alt="Investment Platform" />
        </div>
      </RightSide>
    </Wrapper>
  </div>
);


export const HeaderSection4 = () => (
  <StyledHeaderSection4>
    <Wrapper className="container flexSpaceCenter">
      <RightSide>
        <div
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="1000"
        >
          <img src={Screen2} alt="Investment Platform" />
        </div>
      </RightSide>
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Earn Competitive Returns
            </h1>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
          <div className="font18 semiBold">
            Enjoy attractive interest rates and watch your investments grow while 
            contributing to sustainable economic development.
          </div>
        </div>

      </LeftSide>
    </Wrapper>
  </StyledHeaderSection4>
);



export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">

      <GapWrapper gap={10}>
        <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
          <h1 className="extraBold font60">
            How It <HighlightSpan>Works</HighlightSpan>
          </h1>
        </div>
      </GapWrapper>
      <GridWrapper>
        {walletData.map((val) => {
          const { title, Icon, content } = val;
          return (
            <CardWrapper className="radius8 borderBox">
              <h2 className="flexNullCenter">
                <Icon sx={{ mr: 3, fontSize: 30 }} color="primary" />
                {title}
              </h2>
              <Divider sx={{ my: 3 }} />
              <div className="font14"> {content}</div>
            </CardWrapper>
          );
        })}
      </GridWrapper>
    </div>
  </ContentWrapper>
);

export const Wallet = () => {
  return (
    <>
      <HeaderSection1 />
      <HeaderSection2 />
      <HeaderSection3 />
      <HeaderSection4 />
      <ContentSection />
      <MobileApp />
      <Footer />
    </>
  );
};


// Styled components for HeaderSection2 and HeaderSection4
const StyledHeaderSection2 = styled.div`
  background-color: ${primaryColor};
  color: white;
`;

const StyledHeaderSection4 = styled.div`
  background-color: ${primaryColor};
  color: white;
`;


const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    max-width: 100%;
    height: 100%;
    width: 80%;
    @media (max-width: 768px) {
      width: 80%; // Adjust as needed for mobile
    }
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
