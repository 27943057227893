// screens
import iDash from "./screens/dashboard.png";
import iLoans from "./screens/loans.png";
import iScreen1 from "./screens/screen2.jpg";
import iScreen2 from "./screens/screen3.jpg";
import iScreen3 from "./screens/screen4.jpg";
import iScreen4 from "./screens/screen5.jpg";
import iScreen5 from "./screens/screen6.jpg";
import iScreen6 from "./screens/screen1.jpg";
import iScreen7 from "./screens/screen7.jpg";
// illustrations
import iIlls1 from "./illustrations/illustration1.png";
import engineer from "./illustrations/software_dev.jpg";
import banner from "./illustrations/banner.jpg";

// Impact
import project1 from "./impact/project1.jpg";
import project2 from "./impact/project2.jpg";
import project3 from "./impact/project3.jpg";
import project4 from "./impact/project4.jpg";
import project5 from "./impact/project5.jpg";
import project6 from "./impact/project6.jpg";
import project7 from "./impact/project7.jpg";

export const DashImg = iDash;
export const LoansImg = iLoans;
export const Screen1 = iScreen1;
export const Screen2 = iScreen2;
export const Screen3 = iScreen3;
export const Screen4 = iScreen4;
export const Screen5 = iScreen5;
export const Screen6 = iScreen6;
export const Screen7 = iScreen7;

export const Ills1 = iIlls1;
export const Engineer = engineer;
export const Banner = banner;

export const Project1 = project1;
export const Project2 = project2;
export const Project3 = project3;
export const Project4 = project4;
export const Project5 = project5;
export const Project6 = project6;
export const Project7 = project7;
