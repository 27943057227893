import {
  AccessibilityNew,
  CheckCircle,
  CreditScore,
  Devices,
  Handyman,
  InvertColors,
  LocalPhone,
  LocationOn,
  LockPerson,
  Mail,

} from "@mui/icons-material";
import {
  ShabilImg,
  SharonImg,
  ZestImg,
} from "../assets/img/team/TeamImages";
import CodeIcon from '@mui/icons-material/Code';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import RememberMeIcon from '@mui/icons-material/RememberMe';

import {
  DashImg,
  LonasImg,
  Screen4Img,
  PosImg,
  SmsImg,

} from "../assets/img/screens/ScreenImages";

import {
  BellSVG,
  CardSVG,
  CoinsSVG,
  BankSVG,
  WalletSVG,
  TelecomSVG,
  //DollarSVG,
  SaveSVG,
  SmartSVG,
  UsersSVG,
} from "../assets/svg/Svgs";
import { Tag } from "antd";
import { HighlightSpan, RowWrapper } from "../components/styled/Styled";
import { iDashImg } from "../assets/img/screens/ScreenImages";


import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';


// Sample Data (businessData) structure update

export const impactData = [
  {
    title: "Businesses Empowered",
    Icon: BusinessIcon, // Example icon
    content: "Businesses across Africa have been equipped with the tools to succeed.",
    number: 1200, // Example number
  },
  {
    title: "Community Projects",
    Icon: GroupIcon,
    content: "We have led numerous projects that foster financial inclusion.",
    number: 25,
  },
  {
    title: "Community Projects",
    Icon: PublicIcon,
    content: "We have led numerous projects that foster financial inclusion.",
    number: 25,
  },
  // Add more items as needed
];


// WHY OPTIONS
export const whyData = [
  {
    Icon: LockPerson,
    title: "Unparalleled Security Assurance",
    desc:
      "Our system is fortified with cutting-edge security measures to safeguard your SACCO's funds from internal fraud and external threats. We employ advanced encryption protocols, multi-factor authentication, and secure communication channels to ensure that your members' financial data remains impervious to unauthorized access.",
  },

  {
    Icon: Handyman,
    title: "Seamless AI Powered Fraud Prevention and Detection",
    desc:
      "Our comprehensive Artificial Intelligence Powered risk assessment tools and real-time monitoring capabilities act as vigilant gatekeepers, identifying and mitigating potential risks before they become threats. Our system employs robust fraud detection algorithms and alerts SACCO administrators to any suspicious activities, minimizing the risk of fraudulent transactions within your organization.",
  },
  {
    Icon: InvertColors,
    title: "Transparency and Accountability",
    desc:
      "We prioritize transparency and accountability in every step of the loan management process. Our system offers complete audit trails, ensuring that every transaction is meticulously recorded and can be traced back to its source. This feature not only deters fraudulent activities but also provides a clear view of the financial health of your SACCO, enabling informed decision-making.",
  },

  {
    Icon: AccessibilityNew,
    title: "Member-Centric Approach",
    desc:
      "We understand that your SACCO's success depends on the trust and satisfaction of your members. Our system promotes responsible financial behavior among members and provides exceptional support throughout the loan lifecycle. We offer a user-friendly interface, clear communication channels, and post-loan support to help members navigate any challenges they may encounter.",
  },
];


export const whyGreenData = [
  {
    Icon: AccessibilityNew,
    title: "Explore Green Opportunities",
    desc:
      "Discover a diverse range of green bonds vetted for their positive environmental impact.",
  },

  {
    Icon: LockPerson,
    title: "Invest with Confidence",
    desc:
      "Choose projects aligned with your values and investment goals, fostering sustainability and profitability",
  },
  {
    Icon: InvertColors,
    title: "Carbon Offset Solutions",
    desc:
      "For companies, Everpesa offers carbon offset opportunities, ensuring a positive environmental contribution while supporting their business objectives.",
  },
];


// FAQS
export const faqData = [
  {
    label: "What is Everpesa Technologies Limited?",
    children: (
      <>
        Everpesa Technologies Limited is a forward-thinking fintech company dedicated 
        to reshaping the financial landscape by providing innovative financial and digital solutions. 
        We cater to consumers, businesses, and financial institutions of all sizes, empowering them to 
        take charge of their financial destinies.
      </>
    ),
  },
  {
    label: "What are the core products offered by Everpesa Technologies?",
    children: (
      <>
      Our core products include: 
      <br />
      <br />
      SafariBanking - SACCO and Microfinance Management Application: SafariBanking is a comprehensive platform 
      tailored for SACCOs and microfinance institutions, streamlining operations and promoting financial inclusion.
      <br />
      <br />
      Everpesa Invest - Your Financial Companion: This versatile app connects SACCO members and non-members to their accounts, 
      offering services like secure payments, financial literacy resources, and micro-credit options.
      <br />
      <br />
      Tailored Enterprise Solutions: We provide customized software solutions for various sectors, including 
      telecommunications, retail, industry, and application development.
      <br />
      <br />
      </>
    ),
  },

  {
    label:
      "How does SafariBanking empower SACCOs and microfinance institutions?",
    children: (
      <>
        SafariBanking, our SACCO and microfinance management application, 
        simplifies member account management, loan tracking, contribution management, 
        and provides access to detailed financial reports. It enhances operational 
        efficiency and fosters growth within financial communities.
      </>
    ),
  },
  {
    label: "What benefits does the Everpesa Invest offer SACCO members?",
    children: (
      <>
        SACCO members can access their accounts, apply for loans, 
        make secure payments, and access financial literacy resources. 
        The app also offers micro-investment opportunities tailored to
        their financial goals.
      </>
    ),
  },
  {
    label:
      "Can non-SACCO members use the Everpesa Invest?",
    children: (
      <>
        Yes, non-SACCO members can use Everpesa Invest to access financial services,
        and make investments. The app is designed to cater to a wide range of users,
        from individuals to businesses, providing a seamless and secure platform for
        financial transactions.
      </>
    ),
  },
  {
    label: (
      <p>
       Is the everpesa App ready for use?{" "}
        <Tag color="green">Beta</Tag>
      </p>
    ),
    children: (
      <>
        Yes, the application is ready for use. We are currently in the beta testing phase,
        and we welcome you to join us on this journey. Please contact us to learn more.
      </>
    ),
  },
  {
    label: "What is Everpesa Mobile?",
    children: (
      <>
        Everpesa Mobile is a division of Everpesa Technologies
        Limited that provides customized ICT infrastructure solutions, network
        equipment, mobile devices, and accessories.
      </>
    ),
  },
  {
    label: "What makes Everpesa Technologies unique in the fintech industry?",
    children: (
      <>
        Everpesa Technologies stands out for its commitment to innovation, 
        inclusivity, and technological excellence. We aim to make financial 
        empowerment accessible to all and cater to a wide range of clients, 
        from individuals to enterprises.
      </>
    ),
  },
  {
    label: "What is Everpesa's approach to security?",
    children: (
      <>
        We employ cutting-edge security measures to safeguard your data and
        financial information. Our system is fortified with advanced encryption
        protocols, multi-factor authentication, and secure communication
        channels to ensure that your data remains impervious to unauthorized
        access.
      </>
    ),
  },
  {
    label: "How can I get started with Everpesa Technologies' solutions?",
    children: (
      <>
        You can get started by exploring our website and contacting us for more information. 
        We'll be happy to assist you in selecting the right solutions for your needs.
      </>
    ),
  },
  
];

// PLANS
export const plansData = [
  {
    title: "Free",
    tag: "Experimental",
    desc: "For small SACCOs and MFIs",
    button: "Get Started",
    features: [
      "Test Account",
      "Free features",
    ],
  },
  {
    title: "Basic",
    desc: "For medium sized SACCOs and MFIs",
    button: "Get Started",
    tag: "Recommended",
    features: [
      "Loans",
      "Savings",
      "Clients",
      "Accounting",
      "Reports",
      "Shares",
    ],
  },
  {
    title: "Premium",
    desc: "For large SACCOs and MFIs",
    button: "Get Started",
    tag: "PRO",
    features: [
      "Loans",
      "Savings",
      "Clients",
      "Accounting",
      "Reports",
      "Shares",
      "SMS Banking",
      "Mobile Application",
      "Mobile Money Integration",
    ],
  },
];

// TEAM MEMBERS
const teamModel = (name, title, image) => {
  return { name: name, title: title, image: image };
};

export const bankFeatures = [
  teamModel("Core Banking solution", "Our core banking solution is designed to streamline your operations, from account management to loan tracking.", DashImg),
  teamModel("Comprehensive SACCO Management", "Simplify all aspects of SACCO operations, from savings and loans to member engagement and reporting.", DashImg),
  teamModel("Integrated Loan Origination", "Effortlessly originate loans and connect them with potential investors on Everpesa Invest, ensuring your members have access to the funds they need.", DashImg),
  teamModel("Cloud-Based Convenience", "Access your SACCO's data anytime, anywhere, with our secure and scalable cloud solution.", DashImg),
  teamModel("Empower Your Members", "Give your members the power to manage their finances digitally, improving satisfaction and retention.", DashImg),
];


export const greenInvestingData = [
  {
    title: "Trade Carbon Offset Credits",
    desc:
      "Unlock Sustainable Trading: Join us in pioneering green investment in Africa by trading carbon offset credits. Make an impact on climate change while exploring a sustainable approach to trading. Your journey towards a greener future starts here. ",
    Icon: CoinsSVG,
  },
  {
    title: "Invest in Green Energy",
    desc:
      "Powering a Sustainable Tomorrow: Invest in the future of Africa with our green energy projects. Be part of the revolution that transforms the continent's energy landscape. Your investments not only grow but contribute to the development of clean and sustainable energy solutions. ",
    Icon: SaveSVG,
  },
  {
    title: "Green Agriculture Opportunities",
    desc:
      "Cultivate Prosperity, Cultivate the Planet: Explore green agriculture opportunities and invest in the sustainable growth of Africa. From eco-friendly farming practices to supporting agricultural innovations, your investments on our platform drive positive change in both your portfolio and the environment.",
    Icon: CardSVG,
  },
  {
    title: "Carbon Credits",
    desc:
      "Carbon credits are a key component of national and international emissions trading schemes that have been implemented to mitigate global warming. Carbon credits are awarded to organizations that have reduced their greenhouse gases below their emission quota. Everpesa is pioneering carbon credits to Africa, empowering businesses to grow sustainably.",
    Icon: CoinsSVG,
  },
  {
    title: "Green Bonds",
    desc:
      "Green bonds are fixed-income securities designed to raise capital for low-carbon and climate-friendly projects. Green bonds are a type of sustainable investing and are intended to fund projects that have positive environmental and/or climate benefits. Everpesa is pioneering green bonds to Africa, empowering businesses to grow sustainably.",
    Icon: SaveSVG,
  },
  {
    title: "Green Loans",
    desc:
      "Green loans are loans that are used to fund projects that have positive environmental and/or climate benefits. Green loans are a type of sustainable investing and are intended to fund projects that have positive environmental and/or climate benefits. Everpesa is pioneering green loans to Africa, empowering businesses to grow sustainably.",
    Icon: CardSVG,
  },
];


export const greenData = [
  {
    title: "Green Investment Pioneer",
    Icon: CreditScore,

    content: (
      <p>
        Be part of the movement revolutionizing finance in Africa by supporting projects that make a real impact on the environment.
      </p>
    ),
  },
  {
    title : "Diverse Green Bonds",
    Icon: CreditScore,

    content: (
      <p>
        Explore a curated selection of green bonds, ranging from renewable energy initiatives to sustainable agriculture projects, each contributing to a healthier planet.
      </p>
    ),
  },
  {
    title : "Invest in Tomorrow",
    Icon: CreditScore,

    content: (
      <p>
        Your investment doesn't just grow financially; it grows a greener future for generations to come.
      </p>
    ),
  },
  {
    title : "Your Impact Matters",
    Icon: CreditScore,

    content: (
      <p>
        Investing with Everpesa isn't just about financial returns; it's about creating a legacy of positive change. 
        Your funds contribute directly to projects that address climate change, promote sustainable practices, and 
        build resilient communities.
      </p>
    ),
  },
];

// objectives
export const productsData = [
  {
    title: "SACCO Management",
    desc: (
      <>
        Empowering SACCOs and microfinance institutions with advanced core banking solutions tailored for efficiency and transparency.
      </>
      ),
    Icon: BankSVG,
  },
  
  {
    title: "Everpesa Invest",
    desc: (
      <>
        Offering sustainable investment opportunities that democratize access to financial markets, fostering economic growth and inclusivity.
      </>
    ),
    Icon: WalletSVG,
  },

  {
    title: "Everpesa Mobile",
    desc: (
      <>
        Bridging the digital divide with affordable smartphones and seamless digital financial services, empowering users across Africa.
      </>
    ),
    Icon: TelecomSVG,
  },
];

// features
export const featuresData = [
  {
    title: "Direct Connections",
    desc:
      "Invest directly in businesses and SACCOs.",
    Icon: BellSVG,
  },
  {
    title: "Start Small",
    desc:
      "Micro-investments make it easy to begin.",
    Icon: SaveSVG,
  },
  {
    title: "Impactful Opportunities",
    desc:
      "Support sustainable and responsible projects.",
    Icon: UsersSVG,
  },
  {
    title: "Financial Education",
    desc:
      "Access resources to grow your financial literacy.",
    Icon: SmartSVG,
  },
  {
    title: "Secure and Transparent",
    desc:
      "Trust in a safe investment environment.",
    Icon: CoinsSVG,
  },
  {
    title: "Inclusivity for All",
    desc:
      "Open to all investors, regardless of experience.",
    Icon: CardSVG,
  },
];

// telecommunications features
export const telecommunicationData = [
  {
    title: "Mobile Devices and Accessories",
    desc:
      "We provide customized mobile devices and accessories solutions for consumers.",
    Icon: Devices,
  },
  {
    title: "ICT Infrastructure Solutions",
    desc:
      "We provide customized ICT infrastructure solutions for various sectors, including retail, industry, and enterprise.",
    Icon: LocationOn,
  },
  {
    title: "Network Equipment",
    desc:
      "We provide customized network equipment solutions for various sectors, including retail, industry, and enterprise.",
    Icon: Devices,
  },
];

// testimonials
export const testimonialData = [
  {
    text:
      "SafariBanking made SACCO management a breeze. Loan applications are quick, and our business is thriving with their financial insights.",
    name: "Wabwiire Shabil Ojambo",
    occupation: "Xing Enterprises Limited",
    image: ShabilImg,
  },
  {
    text:
      "Everpesa transformed our business. Their data expertise is a game-changer. Everpesa is indispensable.",
    name: "Sebataya Moses",
    occupation: "Founder Zest Collections",
    image: ZestImg,
  },
  {
    text:
      "Everpesa is my financial companion. Secure transactions, and financial wisdom at my fingertips!",
    name: "Atuhire Sharon",
    occupation: "Olympia Debate Society",
    image: SharonImg,
  },
];

const clients = [
  {
    "title": "SACCOs and Microfinance Institutions (MFIs)",
    "desc": "SafariBanking revolutionizes SACCO management by providing a cloud-based platform that simplifies operations, enhances member engagement, and facilitates seamless loan origination and management. SACCOs can digitize their processes, improve service delivery, and attract investors through Everpesa Invest."
  },
  {
    "title": "Small Businesses and Entrepreneurs",
    "desc": "Everpesa Invest offers micro-investment opportunities, connecting small businesses with community investors interested in supporting local economic development. By listing loans originated from SACCOs on SafariBanking, small businesses gain access to flexible funding options that empower them to expand operations and achieve their goals."
  },
  {
    "title": "Individuals and Communities",
    "desc": "Everpesa Technologies bridges the digital divide with products like Everpesa Mobile, providing smart devices, IT equipment, and digital literacy programs. Through Everpesa Invest, individuals can participate in micro-investments and gain access to financial services previously unavailable, promoting financial empowerment and sustainable economic growth."
  },
];


// target clients
export const targetClientsData = {
 
  title: (
    <p>
      We <HighlightSpan>Build</HighlightSpan> for <HighlightSpan>You</HighlightSpan>
    </p>
  ),
  desc: (
    <div className="flexColumn gap20">
      {clients.map((client, i) => (
        <RowWrapper
          className="flex gap20"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay={i * 100 + 200}
        >
          <div>
            <CheckCircle sx={{ mt: 0.5 }} fontSize="small" color="primary" />
          </div>
          <div>
            <h2>{client.title}</h2>
            <br />
            <p>{client.desc}</p>
          </div>
        </RowWrapper>
      ))}
    </div>
  ),
  Image: <img src={DashImg} alt="clients" />,
};

export const walletData = [
  {
    title: "1. Create an Account",
    Icon: RememberMeIcon,

    content: (
      <p>
        Sign up quickly and easily.
      </p>
    ),
  },
  {
    title: "2. Explore Investments",
    Icon: CardMembershipIcon ,
    content: (
      <p>
        Browse a variety of exciting opportunities.
      </p>
    ),
  },
  {
    title: "3. Invest Smartly",
    Icon: CreditScore,

    content: (
      <p>
        Choose investments that align with your goals.
      </p>
    ),
  },
  {
    title: "4. Watch Your Money Grow",
    Icon: Devices,
    content: (
      <p>
        Enjoy regular updates and returns.
      </p>
    ),
  },
];

export const tradeFinanceData = [
  {
    title: "Invoice Financing",
    Icon: Devices,
    content: (
      <p>
        Accelerate cash flow for small businesses, ensuring quicker payments 
        on outstanding invoices.
        <br />
        - Cash Advances
        <br />
        - Invoice Financing
        <br />
        - Quick Payments
      </p>
    ),
  },
  {
    title: "Green Bonds",
    Icon: CreditScore,

    content: (
      <p>
        Green bonds are fixed-income securities designed to raise capital for low-carbon and climate-friendly projects.
        <br />
        - Sustainable Investing
        <br />
        - Climate Benefits
        <br />
        - Environmental Impact
      </p>
    ),
  },
];

export const businessData = [
  {
    title : "Tailored Enterprise Solutions",
    Icon: CreditScore,

    content: (
      <p>
        We provide customized software solutions for various sectors, including telecommunications, retail, industry, and application development.
      </p>
    ),
  },
  
  {
    title: "AI and Data Labeling",
    Icon: CreditScore,

    content: (
      <p>
        Everpesa provides consulting and infrastructure services to aid organizations in using their data for Machine Learning applications.
      </p>
    ),
  },
  {
    title: "Digital Literacy",
    Icon: CreditScore,

    content: (
      <p>
        We provide resources to help businesses and individuals grow their digital literacy and adapt to the digital age.
      </p>
    ),
  },
  {
    title: "Financial Literacy",
    Icon: CreditScore,

    content: (
      <p>
        We offer financial literacy resources to help individuals and businesses make informed financial decisions.
      </p>
    ),
  },
];

export const telecomData = [
  {
    title : "Smartphones and Accessories",
    Icon: CreditScore,

    content: (
      <p>
        - Everpesa offers a wide range of smartphones for both personal and professional use to keep you connected on the go.
        <br />
        - Products include smartphones, tablets, and wearable technology.
        <br />
        - Explore the latest tech for enhanced productivity and communication.
      </p>
    ),
  },
  {
    title: "Network Services",
    Icon: CreditScore,

    content: (
      <p>
        - Our network services ensure seamless connectivity and efficient data transfer.
        <br />
        - We provide network installation, configuration, and optimization.
        <br />
        - Solutions include LAN and WAN infrastructure for businesses.
      </p>
    ),
  },
  {
    title : "ICT Infrastructure Solutions", 
    Icon: CreditScore,

    content: (
      <p>
        - Everpesa offers a wide selection of IT equipment and related services to meet your technology needs.
        <br />
        - Products include laptops, desktop computers, servers, and peripherals.
        <br />
        - Services encompass setup, maintenance, and technical support.
        <br />
        - ICT infrastructure hardware
      </p>
    ),
  },
  {
    title: "Cloud Services and Internet of Things (IoT)",
    Icon: CreditScore,

    content: (
      <p>
        - Embrace the flexibility and scalability of cloud services with Everpesa.
        <br />
        - Solutions cover cloud hosting, data storage, and backup options.
        <br />
        - Access your data and applications securely from any location.
      </p>
    ),
  },
];

export const engineeringData = [
  {
    title: "Senior Software Engineer",
    Icon: CodeIcon,

    content: (
      <p>
        We are looking for a Senior Software Engineer to produce and implement functional software solutions.
        <br />
        - 5+ years of experience in software development
        <br />
        - Bachelor's degree in Computer Science, Computer Engineering, or related field
        <br />
        - Proficiency in Python, Java or C++
        <br />
        - Experience with SQL and NoSQL databases
        <br />
        - Experience with Linux
        <br />
        - Experience with Git
        <br />
        - Experience with JavaScript, HTML, and CSS
        <br />
        - Experience with React, Node.js, and Express.js
        <br />
        - Experience with AWS
        <br />
        - Experience with Docker
        <br />
        - Experience with Agile development
        <br />
        - Experience with CI/CD
        <br />
        - Experience with unit testing
        <br />
        - Experience with data structures and algorithms
        <br />
      </p>
    ),
  },
  {
    title: "Software Engineer",
    Icon: CodeIcon,
    content: (
      <p>
        We are looking for a Software Engineer to produce and implement functional software solutions.
        <br />
        - 2+ years of experience in software development
        <br />
        - Bachelor's degree in Computer Science, Computer Engineering, or related field
        <br />
        - Proficiency in Python, Java or C++
        <br />
        - Experience with SQL and NoSQL databases
        <br />
        - Experience with Linux
        <br />
        - Experience with Git
        <br />
        - Experience with JavaScript, HTML, and CSS
      </p>
    ),
  },
  {
    title: "Network Engineer",
    Icon: CodeIcon,

    content: (
      <p>
        To support build and maintain Everpesa's IP Core/ Datacom Network products in Uganda.
        <br />
        <b>Responsibilities:</b>
        <br />
         - Design, deploy, monitor and troubleshoot local area networks using a variety of network equipment, software and protocols including layer2/3 switches, spanning tree, fiber, CAT5/6 cable, VLANs, Multicast and network access lists
         <br />
         - Experience on IP project delivery for datacom devices, including preparing network Low Level design, managing technology issue.
         <br />
         - Plan, design, implement, monitor and maintain the enterprise networks
         <br />
         - Define, evaluate and approve design and/or configuration changes
         <br />
         - Install, configure, and support networking and security equipment from a wide range of industry leading vendors including Cisco, Huawei, Juniper, Palo Alto, HP
         <br />
         - Interface with multiple IT departments as the expert networking resource to assess, recommend, project plan and implement network solutions in support of business initiatives
         <br />
         - Proactively monitor and report on overall network service performance and take action to correct deficiencies in monitoring and controlling the quality of individual network services
         <br />
         - Provide highest level escalation expertise for network operations trouble resolution
         <br />
         - The implementation and delivery for IP network project Pre-sales support for testing, bidding or holding workshops
         <br />
         - To support the customers in all technical aspects and improve the relationship to our main service provider accounts
         <br />
         - After-sales technical support Implementation & Maintenance in the field of IP Core/ Datacom Network
         <br />

         <b>Experience and educational background required:</b>
         <br />
         - Bachelor’s degree in Computer Science, Information Technology or a related field
         <br />
         - Professional certification (CCIE, HCIE, CCNP, CCDP)
         <br />
         - Solid background in network administration and architecture
         <br />
         - In-depth understanding of communication protocols (mainly TCP/IP) and routing protocols (e.g. BGP, OSPF)
         <br />
         - Familiarity with access control models and network security
         <br />
         - Experience with network diagnostic, monitoring and analysis tools
         <br />
         - Solid understanding of network operating systems (Huawei, Cisco IOS)
         <br />
         - Sharp troubleshooting skills
         <br />
      </p>
    ),
  },
  {
    title: "Radio Access Network Engineer/Wireless Engineer",
    Icon: CodeIcon,

    content: (
      <p>
        To support build and maintain the performance of the Radio Access Network (RAN) and Wireless Network.
        <br />
        <b>Responsibilities:</b>
        <br />
        - Involves building, enhancing and maintaining the performance of RF/wireless networks, by attending to the site for testing, troubleshooting, calibration, etc.
        <br />
        - Collaborating with vendors and contractors on the implementation and deployment of the network.
        <br />
        - Performs network planning to meet coverage and capacity requirements with specified quality of service and traffic demand.
        <br />
        - Performs network optimisation to improve the operation of the network for better utilisation of available infrastructure and network resources.
        <br />
        - Involves providing wireless coverage & capacity and in-building dimensioning, performance mentoring, site engineering and network audit.
        <br />
        - Design, integration, testing, installation, and support of the wireless network infrastructure.
        <br />
        - Lead wireless network engineering services in support of Customer WAN, LAN & WLAN data network.
        <br />
        - Perform routed/switched network design activities on Management System and wireless access points WAP for a large enterprise data network.
        <br />
        - Works with client support technicians to provide solutions for customers' wireless communications requirements and solutions.
        <br />
        - Investigates, designs, plans, and implements physical and logical wireless communications networks.
        <br />

        <b>Educational background and experience required:</b>

        <br />
        - Bachelors degree in Engineering, Information Technology, Computer Science, or related discipline required.
        <br />
        - more than 5 years of experience in Telecommunications field, - this should include strong experience with multi-vendor wireless products.
        <br />
        - Domain experience: 2G/3G/4G/5G Radio access network
        <br />
        - Experience in RAN Multi vendor Products System such Ericsson
        <br />
        - Experience in RAN Site Engineering, RAN Site Solution, RAN Site Installation.
        <br />
        - International work and multi project experience is desired.
        <br />
        - Competency requirements: Must be a committed individual, able to interact at all levels. Perform other duties as required.
        <br />
        - CERTIFICATION/LICENSE: HCIE, Cisco Certified Networking Professional CCNP, Cisco Certified Network Associate CCNA, Xirrus Wireless Certification and Certified Information Systems Security Professional CISSP a plus, Cisco Wireless Network Administrator CWNA preferred.
      </p>
    ),
  },
];

// address Data
export const addressData = [
  {
    text: "National ICT Innovation Hub, Plot 19-21 Portbell Road Nakawa, Kampala (U)",
    icon: LocationOn,
  },
  {
    text: "Tel: +256 786 493 685",
    icon: LocalPhone,
  },
  {
    text: "Tel: +256 770 703 204",
    icon: LocalPhone,
    //icon: Mail,
  },
  {
    text: "info@everpesa.com",
    icon: Mail,
  },
];
