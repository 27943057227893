import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { Engineer } from "../assets/img/Images";
import { engineeringData } from "../constants/siteData";
import { Divider } from "@mui/material";
import Footer from "../sections/Footer";

export const HeaderSection = () => (
  <div className="">
    <Wrapper className="container flexSpaceCenter">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Engineering at <HighlightSpan>Everpesa</HighlightSpan>
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              Who <b>we</b> are
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            At EverPesa Technologies, we're more than just a team of engineers. 
            We're a diverse group of tech enthusiasts who share a passion for innovation,
            problem-solving, and creating a lasting impact. While our backgrounds span 
            various disciplines, including statistics, physics, mathematics, and of course, 
            software engineering, our common thread is our unwavering dedication to technology 
            and its transformative potential.
            <br />
            <br />
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  Our <b>Engineering</b> Ethos
                </div>
            </div>
            <br />
            <br />
            <b>Passion for Technology:</b> We eat, sleep, and breathe technology. 
            It's not just a job for us; it's a way of life. Our enthusiasm for the 
            latest advancements drives us to continuously seek new solutions and push 
            the boundaries of what's possible.
            <br />
            <br />
            <b>Impact-Driven:</b> Every line of code we write, every solution we design, 
            has a purpose—to make a tangible impact. We're not content with just delivering 
            software; we're here to change the game, empower businesses, and transform lives.
            <br />
            <br />
            <b>Open Source Advocates:</b> Like our customers, we're active members of the 
            open-source community. We understand that collaboration and sharing are at the 
            heart of progress. That's why we contribute to open-source projects and believe 
            in the power of collective innovation.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  What <b>You</b> Will Build:
                </div>
            </div>
            <br />
            <br />
            As a member of our engineering team, you won't just write code; you'll craft 
            software that fuels innovation across EverPesa Technologies. You'll have the 
            opportunity to design high-impact solutions that empower our customers to optimize
            complex workflows, handle vast datasets with ease, and make intuitive decisions. 
            Our product range spans desktop, web, and mobile applications, and we pride ourselves
            on being platform-agnostic.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  Our Priority: <b>Customer Experience</b> 
                </div>
            </div>
            <br />
            <br />
            Above all, our priority is delivering an exceptional experience to our customers. 
            We understand that technology should be an enabler, not a barrier. Whether it's 
            streamlining financial processes or enhancing user experiences, our commitment to 
            excellence shines through in every product we create.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  Who We're <b>Looking For</b> 
                </div>
            </div>
            <br />
            <br />
            We're on the lookout for talented engineers who share our intellectual curiosity, 
            problem-solving prowess, and strong communication skills. We're firm believers in 
            using the right tools for the job, and while we're well-versed in various technologies,
            our core software development is primarily in JavaScript, TypeScript and Python.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  Our <b>Interview Process</b> 
                </div>
            </div>
            <br />
            <br />
            While no two jobs at EverPesa Technologies are the same, our interview process 
            is consistent in its dedication to finding the right talent. It typically begins 
            with a phone interview to gauge your skills and interests. If there's alignment 
            with our mission, we'll invite you for a series of in-person technical and HR interviews, 
            either at our office or on campus. We value diversity, unique perspectives, and the passion
            to make a difference.
            
            <br />
            <br />

          </TitleP>
        </div>

      </LeftSide>
      <RightSide>
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <img src={Engineer} width="100%" alt="engineer" />
        </div>
      </RightSide>
    </Wrapper>
  </div>
);

export const ContentSection = () => (
  <ContentWrapper>
    <div className="container">
      <GridWrapper>
        {engineeringData.map((val) => {
          const { title, Icon, content } = val;
          return (
            <CardWrapper className="radius8 borderBox">
              <h2 className="flexNullCenter">
                <Icon sx={{ mr: 3, fontSize: 30 }} color="primary" />
                {title}
              </h2>
              <Divider sx={{ my: 3 }} />
              <div className="font14"> {content}</div>
            </CardWrapper>
          );
        })}
      </GridWrapper>
    </div>
  </ContentWrapper>
);

export const Engineering = () => {
  return (
    <>
      <HeaderSection />
      <ContentSection />
      <Footer />
    </>
  );
};
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const ContentWrapper = styled.section`
  padding: 70px 0;
  @media (max-width: 960px) {
    padding: 10px 0;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

const RightSide = styled.div`
  width: 50%;
  padding-left: 80px;
  @media (max-width: 960px) {
    width: 100%;
    padding: 30px;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 50px 50px;
  line-height: 1.5rem;
  @media (max-width: 860px) {
    padding: 50px 40px;
  }
`;
