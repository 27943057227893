import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import Footer from "../sections/Footer";

export const HeaderSection = () => (
  <div className="poka-pattern">
    <Wrapper className="container flexSpaceCenter">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              <HighlightSpan>Everpesa</HighlightSpan> Terms of Service
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              <b>26 January 2024</b>
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            Welcome to Everpesa, an investment platform provided by Everpesa Technologies Limited 
            ("Everpesa," "we," "us," or "our"). By accessing or using our services, you agree to 
            comply with and be bound by these Terms of Service ("Terms"). If you do not agree to 
            these Terms, please do not use our services.
            <br />
            <br />
            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>1. Acceptance of Terms</b>
                </div>
            </div>
            <br />
            <br />
            <b>1.1 User Acceptance</b> 
            <br />
            By accessing or using Everpesa, you confirm that you accept these Terms and 
            that you agree to comply with them. If you do not agree to these Terms, you 
            must not use our services.
            <br />
            <br />
            <b>1.2 Eligibility</b> 
            <br />
            To use our services, you must be of legal age and have the legal capacity to enter into contracts.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>2. Registration and User Accounts</b>
                </div>
            </div>
            <br />
            <br />
            <b>2.1 Registration Process</b> 
            <br />
            To access certain features of Everpesa, you may need to register for an account. You agree to provide 
            accurate, current, and complete information during the registration process.
            <br />
            <br />
            <b>2.2 Account Security</b> 
            <br />
            You are responsible for maintaining the confidentiality of your account and password. Notify us 
            immediately if you suspect any unauthorized use of your account.
            <br />
            <br />
            <b>2.3 Termination</b> 
            <br />
            Everpesa reserves the right to terminate or suspend your account for any reason, including violation of these Terms.
            <br />
            <br />


            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>3. Investment Platform</b>
                </div>
            </div>
            <br />
            <br />
            <b>3.1 Services Description</b> 
            <br />
            Everpesa provides an online investment platform, allowing eligible users to explore and invest in various financial products.
            <br />
            <br />
            <b>3.2 Eligibility</b> 
            <br />
            You must meet certain criteria to be eligible to use Everpesa. We reserve the right to determine eligibility criteria.
            <br />
            <br />
            <b>3.3 Risk Disclosure</b> 
            <br />
            Investments carry inherent risks. You acknowledge and understand that your investments may gain or lose value.
            <br />
            <br />
            <b>3.4 Confidentiality</b>
            <br /> 
            We respect the confidentiality of your information. Please refer to our Privacy Policy for details on how we handle your data.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>4. Investment Process</b>
                </div>
            </div>
            <br />
            <br />
            <b>4.1 Investment Options</b>
            <br />
            Everpesa offers a range of investment products. Details about each product, including risks and potential returns, are provided on the platform.
            <br />
            <br />
            <b>4.2 Funding and Withdrawal</b> 
            <br />
            Users can fund their accounts and request withdrawals through designated channels. Processing times and fees may apply.
            <br />
            <br />
            <b>4.3 Risks and Returns</b> 
            <br />
            Investors should carefully consider the risks associated with each investment product. Historical performance does not guarantee future results.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>5. User Responsibilities and Conduct</b>
                </div>
            </div>
            <br />
            <br />
            <b>5.1 Legal Compliance</b>
            <br />
            Users must comply with all applicable laws and regulations while using Everpesa.
            <br />
            <br />
            <b>5.2 Prohibited Activities</b> 
            <br />
            Certain activities are prohibited on Everpesa, including fraudulent, illegal, or harmful behavior.
            <br />
            <br />
            <b>5.3 User Representations</b> 
            <br />
            By using Everpesa, you represent and warrant that all information provided is accurate and complete.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>6. Intellectual Property</b>
                </div>
            </div>
            <br />
            <br />
            <b>6.1 Content Ownership</b>
            <br />
            Everpesa retains ownership of all content provided on the platform.
            <br />
            <br />
            <b>6.2 User License</b> 
            <br />
            Everpesa grants users a limited license to access and use the platform for personal, non-commercial purposes.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>7. Privacy Policy</b>
                </div>
            </div>
            <br />
            <br />
            <b>7.1 Data Collection</b>
            <br />
            Our Privacy Policy outlines how we collect, use, and protect user data.
            <br />
            <br />
            <b>7.2 Security Measures</b> 
            <br />
            Everpesa implements security measures to protect user information. However, no method of transmission over the internet is completely secure.
            <br />
            <br />
            <b>7.3 Cookies</b> 
            <br />
            By using Everpesa, you agree to the use of cookies as described in our Cookie Policy.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>8. Fees and Charges</b>
                </div>
            </div>
            <br />
            <br />
            <b>8.1 Fee Schedule</b>
            <br />
            Details regarding any fees associated with Everpesa are outlined in our Fee Schedule.
            <br />
            <br />
            <b>8.2 Billing Terms</b> 
            <br />
            Users are responsible for any fees incurred. Billing terms and payment procedures are explained in the Fee Schedule.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>9. Disclaimers</b>
                </div>
            </div>
            <br />
            <br />
            <b>9.1 No Financial Advice</b>
            <br />
            Everpesa does not provide financial advice. Users should conduct their own research and seek professional advice if needed.
            <br />
            <br />
            <b>9.2 No Guarantees</b> 
            <br />
            Everpesa does not guarantee the performance or success of any investment.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>10. Liability and Indemnification</b>
                </div>
            </div>
            <br />
            <br />
            <b>10.1 Limitation of Liability</b>
            <br />
            Everpesa is not liable for any direct, indirect, incidental, consequential, or special damages.
            <br />
            <br />
            <b>10.2 User Indemnification</b> 
            <br />
            Users agree to indemnify and hold Everpesa harmless from any claims, liabilities, or expenses arising from their use of the platform.
            <br />
            <br />


            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>11. Termination</b>
                </div>
            </div>
            <br />
            <br />
            <b>11.1 User Termination</b>
            <br />
            Users may terminate their accounts at any time by following the specified procedures.
            <br />
            <br />
            <b>11.2 Everpesa Termination</b> 
            <br />
            Everpesa may terminate or suspend user accounts for any reason, including violation of these Terms.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>12. Governing Law and Jurisdiction</b>
                </div>
            </div>
            <br />
            <br />
            <b>12.1 Applicable Law</b>
            <br />
            These Terms are governed by Ugandan law. Any disputes are subject to the exclusive jurisdiction of the courts in Uganda.
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>13. Amendments to Terms</b>
                </div>
            </div>
            <br />
            <br />
            <b>13.1 Modification Right</b>
            <br />
            Everpesa reserves the right to modify these Terms at any time. Users will be notified of any changes.
            <br />
            <br />
            <b>13.2 Notification</b> 
            <br />
            Notifications regarding changes to the Terms will be communicated through the platform or via the contact information provided.
            <br />
            <br />


            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>14. Miscellaneous</b>
                </div>
            </div>
            <br />
            <br />
            <b>14.1 Force Majeure</b>
            <br />
            Neither party shall be liable for any failure or delay resulting from circumstances beyond their reasonable control.
            <br />
            <br />
            <b>14.2 Entire Agreement</b> 
            <br />
            These Terms constitute the entire agreement between the parties and supersede any prior agreements.
            <br />
            <br />
            <b>14.3 Severability</b> 
            <br />
            If any provision of these Terms is deemed unenforceable, the remaining provisions will remain in effect.
            <br />
            <br />


            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>15. Contact Information</b> 
                </div>
            </div>
            <br />
            <br />
            For questions or concerns about these Terms, please contact:
            <br />
            <br />
            Everpesa Technologies Limited
            <br />
            Kampala, Uganda
            <br />
            +256 786 493 685
            <br />
            info@everpesa.com
            <br />
            <br />

            <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
                <div className="font18 semiBold">
                  <b>16. Effective Date</b> 
                </div>
            </div>
            <br />
            <br />
            These Terms are effective as of the date last updated.
            <br />
            <br />

          </TitleP>
        </div>

      </LeftSide>
    </Wrapper>
  </div>
);


export const TermsService = () => {
  return (
    <>
      <HeaderSection />
      <Footer />
    </>
  );
};
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
