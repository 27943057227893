import React from "react";
import styled from "styled-components";
// Assets
import { contactLink, navLinks } from "../../constants/links";
import { Close } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import { Menu } from "antd";
import { RecursiveMenu } from "../menu/RecursiveMenu";
import { Link } from "react-router-dom";
import logoWhite from "../../assets/svg/logo/logo-white.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  const openSidebar = () => toggleSidebar(!sidebarOpen);

  return (
    <Wrapper className="animate darkGreenBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <div className="flexCenter">
            <img src={logoWhite} alt="logo" style={{ height: 50 , margin: 10  }} />
          </div>
        </div>
        <IconButton
          onClick={() => toggleSidebar(!sidebarOpen)}
          sx={{ color: "white" }}
        >
          <Close />
        </IconButton>
      </SidebarHeader>

      <Menu
        className="whiteColor semiBold sidebar_menu"
        mode="inline"
        theme={null}
        onClick={() => toggleSidebar(!sidebarOpen)}
      >
        {RecursiveMenu(navLinks, openSidebar)}
      </Menu>

      <UlStyle className="flexSpaceCenter">
        <li className="semiBold  font15 pointer">
          <Link
            className="whiteColor"
            to={contactLink}
            onClick={() => toggleSidebar(!sidebarOpen)}
            style={{ padding: "10px 30px 10px 0" }}
          >
            Request a demo
          </Link>
        </li>

        <Button
          className="lightLink"
          variant="contained"
          color="inherit"
          disableElevation
          href="https://safaribanking.com/"
        >
          Sign In
        </Button>
      </UlStyle>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  max-width: 500px;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  z-index: 9999;
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;

const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
