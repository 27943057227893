import React from "react";
import { Why } from "../sections/Why";
import { Banking } from "../sections/Banking";
import Footer from "../sections/Footer";
import { CoreBanking } from "../sections/CoreBanking";

export const BankingPlatform = () => {
  return (
    <>
      <Banking />
      <Why />
      <CoreBanking />
      <Footer />
    </>
  );
};
