import React from "react";
import styled from "styled-components";
import { impactData } from "../constants/siteData";
import Footer from "../sections/Footer";
import CountUp from "react-countup"; 
import { Divider } from "@mui/material";

// Import your images
import {
  Project1, Project2, Project3, Project4,
  Project5, Project6, Project7,
} from '../assets/img/Images';

export const Impact = () => (
  <>
    <HeaderSection />
    <ContentSection />
    <Footer />
  </>
);

// Header Section with sleek, overlapping cards
export const HeaderSection = () => (
  <HeroWrapper>
    <HeroContent>
      <h1>
        Making <Highlight>Financial Impact</Highlight> Happen
      </h1>
      <p>Explore how we drive change through innovative financial solutions.</p>
    </HeroContent>
    <ImageCollage>
      {[Project1, Project2, Project3, Project4, Project5].map((image, index) => (
        <CollageImage key={index} style={{ backgroundImage: `url(${image})` }} />
      ))}
    </ImageCollage>
  </HeroWrapper>
);

// Content Section with sleek cards
export const ContentSection = () => (
  <ContentWrapper>
    <h2>Our Key Impact Metrics</h2>
    <CardGrid>
      {impactData.map(({ title, Icon, content, number }, index) => (
        <ImpactCard key={index}>
          <IconWrapper>
            <Icon sx={{ fontSize: 40 }} />
          </IconWrapper>
          <CardContent>
            <h3>{title}</h3>
            <CountUp start={0} end={number} duration={2} separator="," />
            <p>{content}</p>
          </CardContent>
        </ImpactCard>
      ))}
    </CardGrid>
  </ContentWrapper>
);

// Styled Components

const HeroWrapper = styled.section`
  position: relative;
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f4f5f7;
  overflow: hidden;
`;

const HeroContent = styled.div`
  position: absolute;
  top: 10%;
  left: 5%;
  max-width: 600px;
  z-index: 2;

  h1 {
    font-size: 48px;
    color: #1a1a1a;
    line-height: 1.2;
  }

  p {
    font-size: 18px;
    margin-top: 10px;
    color: #4a4a4a;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 32px;
    }
    p {
      font-size: 16px;
    }
  }
`;

const ImageCollage = styled.div`
  display: flex;
  gap: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 5%;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const CollageImage = styled.div`
  flex: 1;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    height: 150px;
  }
`;

const ContentWrapper = styled.section`
  padding: 60px 10%;
  background-color: white;
  text-align: center;

  h2 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #333;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 28px;
    }
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;

  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const ImpactCard = styled.div`
  background: #f9f9f9;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-8px);
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 15px;
  color: #4caf50;
`;

const CardContent = styled.div`
  h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #1a1a1a;
  }

  p {
    font-size: 16px;
    color: #555;
  }
`;

const Highlight = styled.span`
  color: #4caf50;
`;
