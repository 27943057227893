import React from "react";
import { Header } from "../sections/Header";
import Footer from "../sections/Footer";
import { CoreBanking } from "../sections/CoreBanking";
import { Faqs } from "../sections/Faqs";
import { MobileApp } from "../sections/MobileApp";
import { Telecommunication } from "../sections/Telecommunication";
import { Products } from "../sections/Products";
import { Testimonials } from "../sections/Testimonials";
import { TargetClients } from "../sections/TargetClients";

export const Landing = () => {
  return (
    <>
      <Header />
      <Products />
      <TargetClients />
      <MobileApp />
      <CoreBanking/>
      <Telecommunication />
      <Testimonials />
      <Faqs />
      <Footer />
    </>
  );
};
